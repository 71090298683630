.managers-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

/* Range selector, same style as your user dashboard */
.range-selector {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.range-selector button {
    padding: 8px 12px;
    border: none;
    background-color: var(--button-bg-color, #cc0000);
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.range-selector button:hover {
    background-color: var(--button-hover-color, #990000);
}

.range-selector .active {
    background-color: var(--primary-hover-color, #990000);
}

/* Button to open the "Select Employees" modal */
.employee-selector-button {
    margin-bottom: 10px;
    padding: 8px 12px;
    background-color: var(--button-bg-color, #cc0000);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.employee-selector-button:hover {
    background-color: var(--button-hover-color, #990000);
}

/* Manager "cards" for each employee's average data */
.manager-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1rem;
}

.manager-card {
    border: 1px solid var(--form-border-color, #ddd);
    border-radius: 8px;
    padding: 16px;
    background-color: var(--popup-bg-color, #f5f5f5);
}

.manager-card h2 {
    margin-top: 0;
}

/* Modal overlay and content (similar to your column selection or Frame's popup) */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal-content {
    background: var(--popup-bg-color, #ffffff);
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: auto;
    max-width: 400px;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.popup-header h3 {
    margin: 0;
    font-size: 16px;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
}

/* The 3-column grid for employee names in the modal */
.modal-columns-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    margin-bottom: 10px;
}

.modal-grid-item {
    background-color: #f1f1f1;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
}

.modal-grid-item.selected {
    background-color: var(--primary-color, #cc0000);
    color: var(--background-color, #ffffff);
}

/* Buttons in the modal form (Default, Save) */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.modal-buttons button {
    padding: 6px 12px;
    background-color: var(--button-bg-color, #cc0000);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-buttons button:hover {
    background-color: var(--button-hover-color, #990000);
}