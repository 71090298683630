/* Locations.css */

.locations {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: sans-serif;
    color: #333;
}

.dashboard-header {
    text-align: center;
    margin-bottom: 30px;
}

.dashboard-header h1 {
    font-size: 2rem;
    margin: 0;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
}

.filter-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filter-group label {
    font-weight: 500;
}

.filter-group select,
.filter-group input[type="date"] {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: #fff;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
}

.location-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.location-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.location-card h2 {
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.location-card p {
    margin: 5px 0;
    font-size: 0.95rem;
    line-height: 1.4;
}

/* Special styling for the company-wide card */
.location-card.company-wide {
    border: 2px solid #007BFF;
    /* Accent border */
    background-color: #f9faff;
    /* Light blue background */
}

/* Basic button styling is assumed from Frame, but here’s a minimal override */
button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

button.active {
    box-shadow: inset 0 0 0 2px #007BFF;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .filters {
        flex-direction: column;
    }
}