.dashboard-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

/* The row of 30 / 60 / 90 day buttons */
.range-selector {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.range-selector button {
    padding: 8px 12px;
    border: none;
    background-color: var(--button-bg-color, #cc0000);
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.range-selector button:hover {
    background-color: var(--button-hover-color, #990000);
}

.range-selector .active {
    background-color: var(--primary-hover-color, #990000);
}

/* The card showing averages for the selected range */
.averages-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.averages-card {
    border: 1px solid var(--form-border-color, #ddd);
    border-radius: 8px;
    padding: 16px;
    background-color: var(--popup-bg-color, #f5f5f5);
    width: 300px;
    /* adjust as needed */
}

.averages-card h2 {
    margin-top: 0;
}

/* Weekly performance cards (existing) */
.week-carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    /* optional if you want snap-scrolling */
    gap: 1rem;
}

.week-summary-card {
    flex: 0 0 auto;
    width: 300px;
    scroll-snap-align: start;
    border: 1px solid var(--form-border-color, #ddd);
    border-radius: 8px;
    padding: 16px;
    background-color: var(--popup-bg-color, #ffffff);
}

.week-summary-card h2 {
    margin-top: 0;
}