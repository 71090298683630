/* Container for the entire table */
.table-container {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: auto;
    border: 1px solid var(--form-border-color);
    border-radius: 5px;
    padding: 10px;
    background-color: var(--background-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: var(--text-color);
}

/* Styling table headers */
th {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 8px;
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid var(--form-border-color);
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Styling table rows and columns */
td {
    padding: 8px;
    border-bottom: 1px solid var(--form-border-color);
    white-space: nowrap;
    text-align: center;
    color: var(--text-color);
}

/* Alternating row colors */
tr:nth-child(even) {
    background-color: var(--table-bg-color);
}

tr:nth-child(odd) {
    background-color: var(--popup-bg-color);
}

/* Hover effect for rows */
tr:hover {
    background-color: var(--primary-hover-color);
    color: var(--background-color);
}

/* Customize scrollbar styling */
.table-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 5px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-hover-color);
}

.table-container::-webkit-scrollbar-track {
    background-color: var(--form-border-color);
}

/* Set maximum width for certain columns */
th,
td {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

th,
td {
    min-width: 100px;
}

/* Special styling for the "Employee" column to be wider */
th.employee,
td.employee {
    max-width: 200px;
    text-align: left;
}

/* Special styling for date columns (timestamp) */
th.timestamp,
td.timestamp {
    max-width: 180px;
}

/* Responsive adjustments */
@media (max-width: 768px) {

    td,
    th {
        padding: 6px;
        font-size: 12px;
    }
}

/* Button to open column selector */
.column-selector-button {
    margin-bottom: 10px;
    padding: 8px 12px;
    background-color: var(--button-bg-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.column-selector-button:hover {
    background-color: var(--button-hover-color);
}

/* Overlay behind the modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

/* Modal content box */
.modal-content {
    background: var(--popup-bg-color);
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: auto;
    max-width: 300px;
}

/* Header row with a close button */
.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.popup-header h3 {
    margin: 0;
    font-size: 16px;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
}

/* The 3-column grid for the column list items */
.modal-columns-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    margin-bottom: 10px;
}

/* Each clickable cell in the grid */
.modal-grid-item {
    background-color: #f1f1f1;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    /* prevents accidental text selection */
}

/* Highlight the selected (active) columns */
.modal-grid-item.selected {
    background-color: var(--primary-color);
    color: var(--background-color);
}

/* "Default" and "Save" buttons at the bottom */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.modal-buttons button {
    padding: 6px 12px;
    background-color: var(--button-bg-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-buttons button:hover {
    background-color: var(--button-hover-color);
}